import React from 'react';
import { NavLink } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className='notFound'>
      <div className='notFoundContent'>
        <h3>Thank you for your submission!</h3>
        <p>You can now, go back to my website</p>
        <br />
        <p>I will get back to you as soon as possible.</p>
        <NavLink exact to='/'>
          <i className='fas fa-home' />
          <span>Accueil</span>
        </NavLink>
      </div>
    </div>
  );
};

export default ThankYou;
