import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='notFound'>
      <div className='notFoundContent'>
        <h3>page 404</h3>
        <h3>Désolé,cette page n'existe pas!</h3>
        <div className='goBackLink'>
          <NavLink exact to='/'>
            <i className='fas fa-home' />
            <span>Accueil</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
