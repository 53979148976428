
export const portfolioData = [
  {
    id: 1,
    name: 'Landing Page html-css',
    languages: ['javascript', 'css'],
    languagesIcons: ['/images/html.png', '/images/css.png'],
    source: 'https://github.com/gindev77/Landing-Page-1',
    urllink: 'https://gindev-landing-page-01.netlify.app/',
    info: 'Une landing page responsive faite en html et css.',
    moreinfo: 'Ici, je me suis prété à un exercice de création d\'une landing page en partant d\'une maquette faite par un designer. Aucun framework css n\'est employé. J\'ai utilisé la grid css avec flexbox et usé des média-queries pour adapter le site au plus grand nombre de devices.J\'utilise également des SVG pour designer le theme de la page.J\'emploi les librairies d\'animations animate.css et wow.js pour donner un coté plus dynamique au rendu de la page.',
    picture: '/images/projet1.jpg'
  },
  {
    id: 2,
    name: 'React Marvel Books store',
    languages: ['react', 'css'],
    languagesIcons: ['/images/react.png', '/images/css.png'],
    source: 'https://github.com/gindev77/MarvelBookStore',
    urllink: 'https://marvelbookstore.netlify.app/',
    info: 'Une application faite en ReactJs qui emploie également une Api pour afficher des données.',
    moreinfo: 'Une petite application faite en ReactJs et permettant de parcourir une liste de livres que je possède. les données proviennent d\'un csv que je parse à l\'aide d\'un algorithme. J\'utilise ensuite une Api pour obtenir les images ainsi que les données pour les afficher dans un modal.',
    picture: '/images/projet2.jpg'
  },
  {
    id: 3,
    name: 'ReactMoviesDbApp',
    languages: ['react', 'css'],
    languagesIcons: ['/images/react.png', '/images/bootstrap.png'],
    source: 'https://github.com/gindev77/ReactMovieDbApp',
    urllink: 'https://reactmoviesdbapp.netlify.app',
    info: 'Une autre application faite avec ReactJs qui emploie également une Api pour afficher des données.',
    moreinfo: 'Une petite application faite avec ReactJs qui affiche un slider et des cards. Les composants sont conçus avec bootstrap et Les données proviennent de l\'API TheMovieDb.org',
    picture: '/images/projet6.jpg'
  },
  {
    id: 4,
    name: 'CuttyMonsterFight',
    languages: ['javascript', 'css'],
    languagesIcons: ['/images/html.png', '/images/css.png', '/images/js.png'],
    source: 'https://github.com/gindev77/CuttyMonstersFight',
    urllink: 'https://cuttymonstersfight.netlify.app',
    info: 'Un jeu avec des cartes monstres fait en Vanilla Js avec du css .',
    moreinfo: 'Un jeu fait en Vanilla Js + css n\'utilisant aucun framework css tel que bootstrap. La mise en forme est responsive à l\'aide de media-queries et je n\'utilise que flexBox pour la disposition des éléments.Je me suis prété au jeu du refactoring et me suis familiarisé avec les modules ES6 et semiStandard.js lors de l\'evolution de ce projet.Le jeu est en phase alpha, mais il est entièrement jouable.Dans ce jeu, On endosse la peau d\'un petit monstre et on doit successivement affronter d\'autres petits monstre jusqu\'aux 2 monstres Boss de fin.',
    picture: '/images/projet3.jpg'
  },
  {
    id: 5,
    name: 'Backend Api Crud + Frontend',
    languages: ['javascript', 'nodejs'],
    languagesIcons: ['/images/node.png', '/images/js.png'],
    source: false,
    urllink: 'https://api-meubles.netlify.app/',
    info: 'Création d\'un backend NodeJs fournissant des données avec une APi et création d\'un frontend pour l\'interroger .',
    moreinfo: 'ATTENTION! Ayant été contraint de changer d\'hébergeur pour le backend de mon service, et utilisant un service non playant , je suis dépendant des limitations d\'accès au service. Ceci pour nous encourager à prendre un service payant. L\'hebergeur met en veille l\'application toute les 15 minutes si celle ci \'est pas sollicitée, ce qui occasionne un delais conséquant lors des premieres requêtes sur l\'application. Je compte changer une nouvelle fois d\'hébergeur dès que cela me sera possible. ',
    // moreinfo: 'Creation d\'un backend API nodeJs (CRUD)  fournissant les données d\'un magasin de meuble factice. Les données proviennent d\'un simple fichier Json. Attention! Aucun travail de design de website n\'a été fait! Seule la partie fonctionnelle de l\'application à été réalisée. Il est possible d\'interagire avec le backend (UX minimaliste). Cette APi peut être interrogée avec Postman ou utilisée par mon Site frontend Api call en cliquant sur le lien \'démo\' ou sur le titre.',
    picture: false
  },
  {
    id: 6,
    name: 'Art\'n\'green',
    languages: ['gatsbyjs'],
    languagesIcons: ['/images/gatsby.png', '/images/graphql.png'],
    source: false,
    urllink: 'https://frosty-wescoff-210e63.netlify.app/',
    info: 'site web statique conçu avec gatsbyjs.Utilisation de nombreux plugins et de graphql',
    moreinfo: 'Site web réellement conçu pour un professionnel à l\'aide du framework gatsbyjs, je met en oeuvre de nombreux plugin comme : gatsby-background-image, gatsby-plugin-image ou encore gatsby-plugin-intl pour ne citer qu\'eux.Experience enrichissante qui m\'a permis d\'améliorer mes connaissances de la mise en place de certains concepts liés à l\'hébergement',
    picture: '/images/projet5.JPG'
  }
]
;
