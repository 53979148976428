import React, { Component, Fragment } from 'react';

class Frontend extends Component {
  render () {
    const skillToDisplay = this.props.skills;
    // console.log(this.props.skills);
    return (
      <>
        {
        skillToDisplay
          ? (
            <div className='frontend col m-5'>
              <ul className='newSkills'>
                <h3>Frontend</h3>
                <li>
                  <i className='fas fa-check-square' />
                  Javascript
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  html
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  css
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  sass
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  bootstrap
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  materialUi
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Tailwind css
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  react
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  redux
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  gatsby
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  graphQl
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  contentful
                </li>
              </ul>
            </div>
            )
          : (
            <div className='frontend col m-5'>
              <ul className='oldSkills'>
                <h3>Frontend</h3>
                <li>
                  <i className='fas fa-check-square' />
                  Jquery
                </li>
              </ul>
            </div>
            )
        }
      </>
    );
  }
}

export default Frontend;
