import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Frontend from '../components/knowledges/Frontend';
import Backend from '../components/knowledges/Backend';
import Databases from '../components/knowledges/Databases';
import Graphism from '../components/knowledges/Graphism';
import Development from '../components/knowledges/Development';
import Footer from '../components/Footer';

class Knowledges extends Component {
  state = {
    toggleSkills: true
  };

  changeSkills = () => {
    this.setState({
      toggleSkills: !this.state.toggleSkills,
    });
  };

  render() {
    const skillToDisplay = this.state.toggleSkills;
    // console.log(this.state.toggleSkills)
    return (
      <>
        <div className='knowledges'>
          <Navigation className='col' />
          <div style={{ height: '20px' }}></div>
          <div className="centeringContent">
            <button
              onClick={() => this.changeSkills()}
              className='switch btn btn-primary mx-auto my-1'>
              switch skills
            </button>
          </div>
          
          {
            skillToDisplay
              ? (

                  <h2 >Fresh skills</h2>
                  )
                  :
                  (
                    <h2>old skills</h2>
                    )
                  }
       
          <div className='mainContent container-fluid'>
            <div className=' subContent row mx-auto p-5 '>
              <Frontend skills={this.state.toggleSkills}/>
              <Backend skills={this.state.toggleSkills}/>
              <Databases skills={this.state.toggleSkills}/>
              <Graphism skills={this.state.toggleSkills}/>
              <Development skills={this.state.toggleSkills}/>
            </div>
          <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default Knowledges;
