import React from 'react';
import Navigation from '../components/Navigation';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <div className='contact mb-5'>
      <Navigation />
      <div className='container vertical-center text-center'>
        <p>Si vous souhaitez me contacter, vous pouvez utiliser ce formulaire.</p>
        <div className='container-contact container boxBgColor border vertical-center'>
          <div className=' p-3'>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
