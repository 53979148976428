import React, { Component } from 'react';

class ContactForm extends Component {
  render () {
    return (
      <form
        className='forms'
        name='contact'
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        action='/SuccessForm/'
      >
        <div className='mb-3'>
          <h2 className='p-3 text-center'>Contactez-moi</h2>
          <input type='hidden' name='form-name' value='contact' />
          <div hidden>
            <input name='bot-field' />
          </div>
          <div>
            <label>Prénom :</label>
            <input className='form-control' type='text' name='first-name' />
          </div>
          <div>
            <label>Nom :</label>
            <input className='form-control' type='text' name='last-name' />
          </div>
          <label htmlFor='Input1' className='form-label'>
            Adresse email :
          </label>
          <input
            type='email'
            name='email'
            className='form-control'
            id='mailInput'
            placeholder='name@example.com'
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='Textarea1' className='form-label'>
            Votre message :
          </label>
          <textarea
            name='message'
            className='form-control'
            id='inputTextarea1'
            rows='3'
          />
        </div>
        <button className='btn btn-primary' type='submit' name='submit'>
          envoyer
        </button>
      </form>
    );
  }
}

export default ContactForm;
