import { NavLink } from 'react-router-dom';
import Dropdown from '../components/Dropdown';

const Navigation = () => {
  return (
    <div className='navbar sticky-top '>
      <div className='navigation'>
        <ul>
          <li>
            <NavLink exact to='/' activeClassName='navActive'>
              <span>Accueil</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to='/knowledges' activeClassName='navActive'>
              <span>Competences</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to='/portfolio' activeClassName='navActive'>
              <span>Portfolio</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to='/contact' activeClassName='navActive'>
              <span>Contact</span>
            </NavLink>
          </li>
        </ul>
        <Dropdown />
      </div>
    </div>
  );
};

export default Navigation;
