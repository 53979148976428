import React, { Component, Fragment } from 'react';

class Development extends Component {
  render () {
    const skillToDisplay = this.props.skills;
    // console.log(this.props.skills);
    return (
      <>
        {
        skillToDisplay
          ? (
            <div className='development col m-5'>
              <ul className='newSkills'>
                <h3>Development</h3>
                <li>
                  <i className='fas fa-check-square' />
                  Vscode
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  git - github
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Heroku
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Netlify
                </li>
              </ul>
            </div>
            )
          : (
            <div className='development col m-5'>
              <ul className='oldSkills'>
                <h3>Development</h3>
                <li>
                  <i className='fas fa-check-square' />
                  Vim
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Notepad++
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Bitbucket
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Ovh
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Wordpress
                </li>
              </ul>
            </div>
            )
        }
      </>
    );
  }
}

export default Development;
