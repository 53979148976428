import React from 'react';
import footer from '../media/footer.svg';

const Footer = () => {
  return (
    <div className='row footer'>
      <img src={footer} alt='img footer' />
    </div>
  );
};

export default Footer;
