import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
// import ContactFormspree from './components/ContactFormSpree';
import Portfolio from './pages/Portfolio';
import Knowledges from './pages/Knowledges';
import SuccessForm from './pages/SuccessForm';
import NotFound from './pages/NotFound';

function App () {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/Knowledges' component={Knowledges} />
          <Route path='/Portfolio' component={Portfolio} />
          <Route path='/Contact' component={Contact} />
          <Route path='/Contact' component={SuccessForm} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
