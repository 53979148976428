import React from 'react';
import { NavLink } from 'react-router-dom';

function Dropdown () {
  return (
    <div className='dropdown'>
      <button className='dropbtn btn btn-secondary dropdown-toggle dropdown-menu-start'>
        Menu
      </button>
      <div className='dropdown-content'>
        <NavLink exact to='/'>
          <span>Accueil</span>
        </NavLink>
        <NavLink exact to='/knowledges'>
          <span>Competences</span>
        </NavLink>
        <NavLink exact to='/portfolio'>
          <span>Portfolio</span>
        </NavLink>
        <NavLink exact to='/contact'>
          <span>Contact</span>
        </NavLink>
      </div>
    </div>
  );
}

export default Dropdown;
