import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Card from '../components/Card';
import ModalInfo from '../components/ModalInfo';
import { portfolioData } from '../data/portfolioData';

class Portfolio extends Component {
  state = {
    projects: portfolioData,
    showInfo: false,
    modalName: '',
    modalUrlLink: '',
    modalSource: '',
    modalInfo: '',
  };

  showModal = (name, urllink, source, info) => {
    this.setState({
      showInfo: true,
      modalName: name,
      modalUrlLink: urllink,
      modalSource: source,
      modalInfo: info,
    });
  };

  hideModal = () => {
    this.setState({ showInfo: false });
  };

  render() {
    let {
      projects,
      showInfo,
      modalUrlLink,
      modalName,
      modalSource,
      modalInfo,
    } = this.state;

    const sourceInInModal = this.state.modalSource && (
      <a
        type='button'
        className='btn btn-secondary mx-5 my-1'
        href={modalSource}
        target='blank'
        rel='noreferrer'>
        Code source
      </a>
    );

    //display modal if shoInfo = true :
    const resultInModal = this.state.showInfo && (
      <div className='showInfos '>
        <div>
          <div className='infosContent d-flex flex-column'>
            <div className='head'>
              <h2>
                <a href={modalUrlLink} target='_blank' rel='noreferrer'>
                  {modalName}
                </a>
              </h2>
              
            </div>
            <p className='text'>{modalInfo}</p>
            <a
              type='button'
              className='btn btn-primary mx-5 my-1'
              href={modalUrlLink}
              target='_blank'
              rel='noreferrer'>
              Demo
            </a>
            {/* {sourceInInModal} */}
            <div className='button return text-center p-4' onClick={this.hideModal}>
              Retourner sur la page
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className='portfolio'>
        <Navigation />
        <div className='container-fluid mt-5 showInfos'>
          <div className=' row mx-auto  boxBgColor border '>
            {projects.map((item) => {
              return (
                <Card
                  className='m-5'
                  key={item.id}
                  item={item}
                  showModal={this.showModal}
                />
              );
            })}
          </div>
        </div>
        <ModalInfo>{resultInModal}</ModalInfo>
      </div>
    );
  }
}

export default Portfolio;
