import React, { Component } from 'react';
import noImage from '../media/no-image.jpg';

class Card extends Component {
  render () {
    const { name, languagesIcons, source, info, moreinfo, picture, urllink } =
      this.props.item;
    const { showModal } = this.props;
    // console.log(picture);
    const renderPicture = picture ? (
      <img height='181.3' width='262' src={picture} alt='' onClick={() => showModal(name, urllink, source, moreinfo)} />
    )
      : (
        <img height='181.3' width='262' src={noImage} alt='' onClick={() => showModal(name, urllink, source, moreinfo)} />
        );
    let idIcon = 0;
    return (
      <div className='card zoomer' style={{ width: '18rem' }}>
        <div className='containerIcons p-4'>
          {languagesIcons.map((icon) => {
            return (
              <img
                className='languages'
                src={icon}
                alt=''
                key={name + idIcon++}
                width='48'
                height='48'
              />
            );
          })}
        </div>
        {renderPicture}
        <div className='card-body d-flex flex-column'>
          <h5 className='card-title'>{name}</h5>
          <p className='card-text'>{info}</p>
          <button onClick={() => showModal(name, urllink, source, moreinfo)} className='btn btn-primary mx-5 my-1'>
            + d'info
          </button>
        </div>
      </div>
    );
  }
}

export default Card;
