import React, { Component, Fragment } from 'react';

class Graphism extends Component {
  render () {
    const skillToDisplay = this.props.skills;
    // console.log(this.props.skills);
    return (
      <>
        {
        skillToDisplay
          ? (
            <div className='graphism col m-5'>
              <ul className='newSkills'>
                <h3>Graphism</h3>
                <li>
                  <i className='fas fa-check-square' />
                  Svg
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Inkscape
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Figma
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  Photoshop
                </li>
              </ul>
            </div>
            )
          : (
            <div className='graphism col m-5'>
              <ul className='oldSkills'>
                <h3>Graphism</h3>
                <li>
                  <i className='fas fa-check-square' />
                  n/a
                </li>
              </ul>
            </div>
            )
        }
      </>
    );
  }
}

export default Graphism;
