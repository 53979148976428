import React, { Component, Fragment } from 'react';

class Backend extends Component {
  render () {
    const skillToDisplay = this.props.skills;
    // console.log(this.props.skills);
    return (
      <>
        {
        skillToDisplay
          ? (
            <div className='backend col m-5'>
              <ul className='newSkills'>
                <h3>Backend</h3>
                <li>
                  <i className='fas fa-check-square' />
                  Nodejs
                </li>
              </ul>
            </div>
            )
          : (
            <div className='backend col m-5'>
              <ul className='oldSkills'>
                <h3>Backend</h3>
                <li>
                  <i className='fas fa-check-square' />
                  php
                </li>
                <li>
                  <i className='fas fa-check-square' />
                  shell bash
                </li>
              </ul>
            </div>
            )
        }
      </>
    );
  }
}

export default Backend;
