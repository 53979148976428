import React from 'react';
import Navigation from '../components/Navigation';
import react from '../media/icones Dev Svg/react.svg';
import reactmini from '../media/icones Dev Svg/react-mini.svg';
import js from '../media/icones Dev Svg/javascript.svg';
import css from '../media/icones Dev Svg/css.svg';
import html from '../media/icones Dev Svg/html.svg';
import nodejs from '../media/icones Dev Svg/nodejs.svg';
import gatsby from '../media/icones Dev Svg/gatsby.svg';

const Home = () => {
  return (
    <div className='home'>
      <Navigation />
      <div className='container'>
        <div className='logo-container-1'>
          <img
            className='logo-4 logo-icones bounceIn'
            src={html}
            alt='icone html5'
          />
          <img
            className='logo-5 logo-icones rollerLeft'
            src={css}
            alt='icone css3'
          />
          <img
            className='logo-1 logo-icones spinner'
            src={react}
            alt='icone react'
          />
          <img
            className='logo-2 logo-icones slideRight'
            src={js}
            alt='icone javascript'
          />
          <img
            className='logo-3 logo-icones slideDown'
            src={nodejs}
            alt='icone nodejs'
          />
          <img
            className='logo-6 logo-icones zoomer'
            src={gatsby}
            alt='icone gatsby'
          />
        </div>
        <div className='row'>
          <div className='contentTitle col-lg vertical-center '>
            <h1>Gilles Giannini</h1>
            <h2 className='pb-5'>Portfolio Développeur Web</h2>
            <p>
              Bonjour! Voici mon portfolio développeur Javascript/React.
              <br />
              Ce portfolio montre juste quelques unes de mes réalisations et compétences en développement frontend faite en Javascript et ReactJs.
              <br />
              J'ai aussi quelques compétences en backend avec nodeJs/MongoDb.
              <br />
            </p>
          </div>
          <div className='contentRight col '>
            <div className='logo-container-2'>
              <span>
                <img
                  className='logo-4 logo-icones bounceIn'
                  src={html}
                  alt='icone html5'
                />
                <img
                  className='logo-5 logo-icones rollerLeft'
                  src={css}
                  alt='icone css3'
                />
              </span>
              <span>
                <img
                  className='logo-1 logo-icones'
                  src={react}
                  alt='icone react'
                />
              </span>
              <span>
                <img
                  className='logo-2 logo-icones slideRight'
                  src={js}
                  alt='icone javascript'
                />
                <img
                  className='logo-3 logo-icones slideDown'
                  src={nodejs}
                  alt='icone nodejs'
                />
                <img
                  className='logo-6 logo-icones zoomer'
                  src={gatsby}
                  alt='icone gatsby'
                />
              </span>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='signature pt-4'>
            <p>Gilles GIANNINI - GinDev77 - 2021</p>
            <p>site web powered by <img
              className='logo-1 logo-icones spinner'
              src={reactmini}
              alt='icone react'
                                   />
            </p>
          </div>
          <span className='socialNetwork'>
            <ul>
              <li>
                <a
                  href='https://www.linkedin.com/in/gilles-giannini1977'
                  target='blank'
                  rel='noopener noreferer'
                >
                  <i className='fab fa-linkedin' />
                </a>
              </li>
              <li>
                <a
                  href='https://github.com/gindev77'
                  target='blank'
                  rel='noopener noreferer'
                >
                  <i className='fab fa-github' />
                </a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Home;
